import { Link } from 'react-router-dom';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

function HomePage() {
    return (
        <Container>
            <Row className="px-4 my-5">
                <Col xs={4} sm={6}>
                    <Image src="/img/logo.png" fluid />
                </Col>
                <Col sm={6}>
                    <h1 className="font-weight-light">Expedition Africa</h1>
                    <p className="mt-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a dolor malesuada, congue augue sed, rutrum nibh. Maecenas eget aliquet enim. Vivamus lectus augue, posuere vel neque non, pretium condimentum ipsum. Suspendisse non lorem nunc. Vivamus ut ipsum aliquam turpis fermentum aliquet egestas at purus. Vestibulum luctus porttitor rutrum. Etiam molestie dui risus, et vulputate massa laoreet in. Curabitur convallis nisl dui, elementum molestie justo pulvinar vel. Mauris semper enim ex, vitae maximus mi commodo lacinia. Pellentesque ornare vulputate dignissim. Proin vel neque viverra, pretium libero sit amet, varius tellus. Nulla tristique eleifend elit in fringilla. Cras porttitor urna enim, ut accumsan erat pulvinar a. Praesent a sollicitudin massa. Aliquam et ultricies dolor.
                        <br/><br/>
                    </p>

                    <Link to={{ pathname: '/gallery' }}>
                        <Button className="custom-button mt-4">Check Out the Gallery</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}
export default HomePage;