import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Cropper from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function ProfilePage() {
    const [src, setSrc] = useState(null); // Original uploaded image
    const [crop, setCrop] = useState({ aspect: 1, width: 100 }); // Initialize crop with width
    const [croppedImageUrl, setCroppedImageUrl] = useState(null); // Final cropped image

    // Handle file input change (on file upload)
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSrc(reader.result); // Display the uploaded image
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle image cropping
    const onCropComplete = (crop) => {
        if (src && crop.width && crop.height) {
            createCroppedImage(crop);
        }
    };

    // Generate cropped image from the original
    const createCroppedImage = (crop) => {
        const image = new Image();
        image.src = src;

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // Convert canvas to a data URL for display
        const croppedImageUrl = canvas.toDataURL('image/jpeg');
        setCroppedImageUrl(croppedImageUrl);
    };

    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>Profile Page</h1></Col>
            </Row>

            <Row className="px-4 my-5">
                <Col sm={6}>
                    <Form>
                        {/* Name Field */}
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" />
                        </Form.Group>

                        {/* Profile Picture Field with Cropper */}
                        <Form.Group className="mb-3" controlId="formProfilePicture">
                            <Form.Label>Profile Picture</Form.Label>
                            <Form.Control type="file" accept="image/*" onChange={handleImageUpload} />
                            <Form.Text className="text-muted">
                                Upload and crop your profile picture.
                            </Form.Text>

                            {/* Preview Original Uploaded Image */}
                            {src && (
                                <div className="mt-3">
                                    <h5>Uploaded Image Preview:</h5>
                                    <Image src={src} rounded fluid />
                                </div>
                            )}

                            {/* Image Cropper */}
                            {src && (
                                <Cropper
                                    src={src}
                                    crop={crop}
                                    onChange={(newCrop) => setCrop(newCrop)}
                                    onComplete={onCropComplete}
                                    aspect={1} // Ensure the image is cropped in a square aspect ratio
                                    keepSelection={true}  // Keep the crop selection even if it loses focus
                                />
                            )}

                            {/* Display Cropped Image */}
                            {croppedImageUrl && (
                                <div className="mt-3">
                                    <h5>Cropped Image Preview:</h5>
                                    <Image src={croppedImageUrl} roundedCircle fluid />
                                </div>
                            )}
                        </Form.Group>

                        {/* About Me Blurb Field */}
                        <Form.Group className="mb-3" controlId="formAboutMe">
                            <Form.Label>My story</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="A great story" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Update
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ProfilePage;
