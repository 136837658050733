/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "africasummitapi",
            "endpoint": "https://jhsl85npi2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ckrngn6gk5cejm56szbzpmaxvm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lgdm2qcma5ez5actj4hql5whtu",
    "aws_cognito_identity_pool_id": "us-east-1:ffcd1bca-9100-4454-8b37-241f25d156a9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9CDWWiHlJ",
    "aws_user_pools_web_client_id": "1740o3jegb9m3q1e08l2p0fs4m",
    "oauth": {
        "domain": "summitafrica57811860-57811860-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3030/,https://afrikasummit.com/",
        "redirectSignOut": "http://localhost:3030/,https://afrikasummit.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "AMAZON"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "summitafrica29dfa0d5b3b1402b90ea48981f8c26cc1adfd-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
