import { Route, Routes } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { Authenticator, View, Image, useTheme } from '@aws-amplify/ui-react';
import awsExports from "./aws-exports";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';

import SiteFooter from './components/common/SiteFooter';
import SiteNav from './components/common/SiteNav';
import HomePage from './components/home/Home';
import GalleryPage from './components/gallery/GalleryPage';

import LoginPage from './components/auth/LoginPage';
import RegisterPage from './components/auth/RegisterPage';
import MembersPage from './components/members/MembersPage';
import ProfilePage from './components/auth/ProfilePage';

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Africa Summit"
          src="/img/logo.png"
        />
      </View>
    );
  }
};

function App() {
  return (
    <Authenticator loginMechanism={['email']} components={components}>
      {({ signOut, user}) => (
        <div>
          <SiteNav logOut={signOut} />
          <Routes>
            <Route path='*' element={<HomePage/>}/>
            <Route path='/' exact={true} element={<HomePage/>}/>
            <Route path='/login' element={<LoginPage/>}/>
            <Route path='/register' element={<RegisterPage/>}/>
            <Route path='/gallery' element={<GalleryPage/>}/>
            <Route path='/members' element={<MembersPage/>}/>
            <Route path="/profile" element={<ProfilePage/>}/>
          </Routes>
          <SiteFooter></SiteFooter>
        </div>
      )}
    </Authenticator>
    
  );
}

export default App;
