import React, { useEffect, useState } from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal'; // Modal for enlarging images
import ProgressBar from 'react-bootstrap/ProgressBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';

import { list, getUrl, uploadData, remove } from 'aws-amplify/storage';

function GalleryPage() {
  const [files, setFiles] = useState([]); // Selected files for upload
  const [progress, setProgress] = useState(0); // Progress bar state
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedItem, setSelectedItem] = useState(null); // Selected item for modal
  const [galleryItems, setGalleryItems] = useState([]); // Store gallery items


  const getRandomRotation = () => {
        const min = -5;
        const max = 5;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

  // Function to fetch gallery items from S3
  const fetchGalleryItems = async () => {
    try {
      const result = await list({path: ''}); // List all items in S3 bucket
      const items = await Promise.all(result.items.map(async (item) => ({
        key: item.key,
        url: await getUrl({path: item.path}) // Get signed URLs for each item
      })));
      setGalleryItems(items);
    } catch (error) {
      console.error('Error fetching gallery items:', error);
    }
  };

  useEffect(() => {
    fetchGalleryItems(); // Fetch gallery items on component mount
  }, []);

  const deleteContent = async (url) => {
    try {
        console.log(url);
        const cleanedFilePath = url.pathname.replace(/^\/+/, '');
        await remove({path: cleanedFilePath});  // Remove the file from S3
        console.log(`File deleted successfully: ${cleanedFilePath}`);
        // Refresh the gallery after deletion
        fetchGalleryItems();
    } catch (error) {
        console.error('Error deleting file:', error);
    }
};

  // Handle file input change
  const handleFileChange = (event) => {
    setFiles(event.target.files); // Store selected files
  };

  // Function to upload files to S3
  const uploadContent = async () => {
    if (files.length === 0) {
      return;
    }

    for (const file of files) {
      try {
        const fileKey = `${Date.now()}_${file.name}`;
        
        // Upload file with progress tracking
        await uploadData({
            path: fileKey, 
            data: file, 
            options: {
                onProgress: ({ transferredBytes, totalBytes }) => {
                    // const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(Math.round((transferredBytes / totalBytes) * 100)); // Update progress state
                }
            }
        });

        console.log(`File uploaded successfully: ${fileKey}`);
        setProgress(0); // Reset progress bar after each file upload

        // Refresh the gallery
        fetchGalleryItems();
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  // Handle image click to show modal
  const handleImageClick = (item) => {
    setSelectedItem(item); // Set the selected item for the modal
    setShowModal(true); // Show the modal
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <Container>
      <Row className="px-4 my-5">
        <Col>
          <h1>Gallery</h1>
        </Col>
      </Row>

      {/* File Upload Form */}
      <Row className="px-4 my-5">
        <Col sm={3}>
          <h2>Upload Media</h2>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Label>Photo / Video</Form.Label>
              <Form.Control type="file" multiple accept="image/*, video/*" onChange={handleFileChange} />
            </Form.Group>
          </Form>
          <Button variant="primary" type="button" onClick={uploadContent}>Upload</Button>

          {/* Progress Bar */}
          {progress > 0 && progress < 100 && (
            <ProgressBar now={progress} label={`${progress}%`} className="mt-3" />
          )}
        </Col>
      
      {/* Display Gallery Items */}
      <Row>
      {galleryItems.slice().reverse().map((item, index) => {
        // Check if item and item.key are defined before proceeding
        if (!item) {
            console.warn('Undefined item or key:', item); // Log if item or key is undefined
            return null; // Skip rendering this item
        }

        
        const isVideo = item.url.url.pathname.toString().toLowerCase().endsWith('.mp4') || item.url.url.pathname.toString().toLowerCase().endsWith('.mov');

        return (
            <Col key={index} className="px-2 my-2">
            <Card className="my-polaroid-card" 
                  style={{transform: `rotate(${getRandomRotation()}deg)`,}}>
                <div onClick={() => handleImageClick(item)}>
                    {isVideo ? (
                    <Card.Body>
                        <video width="100%" controls>
                        <source src={item.url.url.href} type="video/mp4" />
                        Your browser does not support the video tag.
                        </video>
                    </Card.Body>
                    ) : (
                    <Card.Img src={item.url.url.href} variant="top" loading="lazy"/>
                    
                    )}

                </div>
                <Row>
                    <Col>
                        <Button
                            variant="outline-secondary"
                            href={item.url.url.href}
                            download
                            className="mt-2">
                                Download
                        </Button>
                    </Col>
                    <Col>
                        {/* <Button variant="danger" className="mt-2" onClick={() => deleteContent(item.url.url)}> Delete</Button> */}
                    </Col>
                </Row>
            </Card>
            </Col>
        );
        })}
      </Row>
      {/* Modal for Enlarged Image/Video */}
      {selectedItem && (
        <Modal show={showModal} onHide={handleCloseModal} centered size="xl">
          <Modal.Body>
            {selectedItem.url.url.pathname.toString().toLowerCase().endsWith('.mp4') || selectedItem.url.url.pathname.toString().toLowerCase().endsWith('.mov') ? (
              <video width="100%" controls>
                <source src={selectedItem.url.url.href} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={selectedItem.url.url.href} alt="Enlarged item" width="100%" />
            )}
          </Modal.Body>
        </Modal>
      )}
      </Row>
    </Container>
  );
}

export default GalleryPage;
