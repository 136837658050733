import React, { useEffect, useState } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/Card";
import { get } from 'aws-amplify/api';

function MembersPage() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchUsers = async () => {
        try {
            // Make the API call to list users
            const restOperation = get({ 
                apiName: 'africasummitapi',
                path: '/listUsers' 
              });
            const response = await restOperation.response;
            const usersData = await response.body.json();

            const mergeUsers = mergeUsersByEmail(usersData)
            setUsers(mergeUsers);
        } catch (err) {
            setError(err.message || 'Error fetching users');
        } finally {
            setLoading(false);
        }
    };

    const mergeUsersByEmail = (usersData) => {
        const usersByEmail = {};

        usersData.forEach(user => {
            const emailAttr = user.Attributes.find(attr => attr.Name === 'email');
            if (emailAttr) {
                const email = emailAttr.Value;

                if (!usersByEmail[email]) {
                    // First time seeing this email, create an entry
                    usersByEmail[email] = {
                        ...user,
                        socialProviders: [getSocialProvider(user)]
                    };
                } else {
                    // Merge this user under the same email
                    usersByEmail[email].socialProviders.push(getSocialProvider(user));
                }
            }
        });

        // Return an array of merged users
        return Object.values(usersByEmail);
    };

    // Function to get the social provider from the user
    const getSocialProvider = (user) => {
        const identitiesAttr = user.Attributes.find(attr => attr.Name === 'identities');
        if (identitiesAttr) {
            const identities = JSON.parse(identitiesAttr.Value);
            return identities[0]?.providerName || 'Unknown';
        }
        return 'Unknown';
    };

    useEffect(() => {
        fetchUsers(); // Fetch users on component mount
    }, []);

    if (loading) return <p>Loading users...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>Expedition Members</h1></Col>
            </Row>
            <Row>
                {
                    console.log("Users --- " + users.response)
                }
                {
                users.map((user, index) => (
                    <Col key={index} className="px-2 my-2">
                        <Card>
                            <Card.Body>
                                <Card.Title>{user.Username}</Card.Title>
                                <Card.Text>Email: {user.Attributes.find(attr => attr.Name === 'email').Value}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default MembersPage;
