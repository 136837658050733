import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function SiteNav(props) {
    const handleLogout = () => {
        props.logOut();
    }

    return (
        <header>
            <Navbar bg="dark" expand="lg" variant='dark'>
                <Container>
                    <Navbar.Brand>Expedition Africa</Navbar.Brand>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id="basic-navbar=nav">
                        <Nav className="ms-md-auto"> 
                            <Nav.Link href='/members'>Expedition Members</Nav.Link>
                        </Nav>
                        <Nav className='ms-md'>
                        <Nav.Link href='/profile'>Profile</Nav.Link>
                        </Nav>
                        <Nav className='ms-md'>
                        <Nav.Link href='/gallery'>Gallery</Nav.Link>
                        </Nav>
                        <Nav className='ms-md'>
                            {/* <Nav.Link href='/login'>Login</Nav.Link>
                            <Nav.Link href='/register'>Register</Nav.Link> */}
                            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                        </Nav>
                        {/* <Nav className='ms-md-auto'>
                            <Nav.Link href='/about'>About</Nav.Link>
                        </Nav>
                        <Nav className='ms-md-auto'>
                            <Nav.Link href='/gallery'>Gallery</Nav.Link>
                        </Nav> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}
export default SiteNav;